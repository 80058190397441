import axios, { AxiosResponse } from "axios";
import { IItem, IReturnItem } from "../types/IItem";
import { ISearch } from "../types/ISearch";
import { ISettings } from "../types/ISettings";
import { IMenu } from "../types/IMenu";
import { IReceipt } from "../types/IReceipt";
import { IReceiptData } from "../types/IReceiptData";
import { IProduct } from "../types/IProduct";
import { IExtra } from "../types/IExtra";
import { IVariation } from "../types/IVariation";
import { IInstruction } from "../types/IInstruction";
import { IOption } from "../types/IOption";
import { INewProduct } from "../types/INewProduct";
import { INewOption } from "../types/INewOption";
import { IInstructionCopier } from "../types/IInstructionCopier";

const baseConfig = {
  baseURL: "https://eposapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
  validateStatus: () => true,
};

export const getSettings = (token: string): Promise<ISettings | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .get("Settings", config)
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};

export const createOrder = (
  data: IReceiptData,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Receipt/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// product
export const storeProduct = (
  product: IProduct,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Product/Add", product, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};
export const updateProduct = (
  product: IProduct,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Product/Update", product, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};
export const deleteProduct = (
  product: IProduct,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Product/Delete", product, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

// extras
export const storeExtra = (
  extra: IExtra,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Extra/Add", extra, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// Variation
export const storeVariation = (
  data: IVariation,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Variation/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteVariation = (
  data: IVariation,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Variation/Delete", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// Instruction
export const storeInstruction = (
  data: IInstruction,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Instruction/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const updateInstruction = (
  data: IInstruction,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Instruction/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteInstruction = (
  data: number,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Instruction/Delete", data, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const copyProductInstruction = (
  data: IInstructionCopier,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Instruction/CopyInstructions", data, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

//add multiple instructions
export const addInstruction = (
  instructions: IInstruction[],
  productId: number,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;

  const data = {
    instructions: instructions,
    id: productId,
  };

  return axios
    .post("Instruction/Multi", data, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

// Options
export const storeOption = (
  data: INewOption,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Option/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};
export const deleteOption = (
  op: IOption,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Option/Delete", op, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const getReceipts = (
  startDate: Date,
  endDate: Date,
  token: string
): Promise<IReceipt[] | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  const dateRange = {
    start: startDate,
    end: endDate,
  };

  return axios
    .post("Receipt/Receipts", dateRange, config)
    .then((response: AxiosResponse<IReceipt[]>) => {
      return response.data;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}
