import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { IInstruction } from "../../../types/IInstruction";
import { deleteInstruction, getSettings } from "../../../services/Service";
import { ISearchData } from "../../../types/ISearchData";
import { useNavigate } from "react-router-dom";

export interface IInstructionsProps {}

export const Instructions: React.FC<IInstructionsProps> = (
  props: IInstructionsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [instructions, setInstructions] = useState<IInstruction[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [removeInstructionId, setRemoveInstructionId] = useState(-1);

  const navigate = useNavigate();

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setInstructions(response.menu.instructions);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function edit(i: IInstruction) {
    navigate("/AddInstruction/" + i.id);
  }

  async function removeInstruction() {
    const ins: IInstruction = {
      id: removeInstructionId,
      uuid: "",
      productId: 0,
      description: "",
      image: "",
      position: 0,
      price: 0,
      qty: 0,
      required: false,
      isCommon: false,
    };

    const response = await deleteInstruction(
      ins.id,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      getBranchSettings();
      setShowConfirm(false);
    }
  }

  const confirmView = (): JSX.Element => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Are you sure?</h5>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="danger"
              size="lg"
              onClick={() => removeInstruction()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function remove(id: number) {
    setShowConfirm(true);
    setRemoveInstructionId(id);
  }

  function getProductName(productId: number) {
    const product = context.BranchMenu.getMenu().products.find((element) => {
      return element.id === productId;
    });

    return product ? product.description : <></>;
  }

  const row = (i: IInstruction) => (
    <>
      <TableRow>
        <TableCell positive>{getProductName(i.productId)}</TableCell>
        <TableCell positive>{i.description}</TableCell>
        <TableCell positive>{i.image}</TableCell>
        <TableCell positive>{i.position}</TableCell>
        <TableCell positive>{i.price.toFixed(2)}</TableCell>
        <TableCell positive>{i.required ? "Yes" : "No"}</TableCell>
        <TableCell positive>{i.isCommon ? "Yes" : "No"}</TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => edit(i)}>
            Edit
          </Button>
        </TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => remove(i.id)}>
            X
          </Button>
        </TableCell>
      </TableRow>
    </>
  );

  const variations = (): JSX.Element => (
    <>
      <h1>Instructions</h1>
      <hr />
      <div>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Product</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Image Url</TableHeaderCell>
              <TableHeaderCell>Position</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Required</TableHeaderCell>
              <TableHeaderCell>Common</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {instructions
              .sort((a, b) => (a.productId > b.productId ? 1 : -1))
              .map((data) => row(data))}
          </TableBody>
        </Table>
      </div>
    </>
  );

  return (
    <>
      <>{variations()}</>
      <>{confirmView()}</>
    </>
  );
};
