import React, { useContext, useEffect, useState } from "react";
import { Login } from "../login";
import ApplicationContext from "../../context/ApplicationContext";
import { ViewPage } from "../../types/ViewPage";
import { IManagerProps } from "./IManagerProps";

import { getSettings } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IInstruction } from "../../types/IInstruction";
//import { MenuItem, Menu, MenuHeader, MenuMenu, Input } from "semantic-ui-react";

import { ITableData } from "../../types/ITableData";
//import "./style.css";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { AllProducts } from "../pages/products/AllProducts";
import { AddProduct } from "../pages/products/AddProduct";
import { AddExtra, Extras } from "../pages/extras";
import { AddInstructions, Instructions } from "../pages/Instructions";
import { AddVariation, Variations } from "../pages/variation";
import { AddOption, Options } from "../pages/Options";
import { MultiInstructions } from "../pages/Instructions/MultiInstructions";
import { ProductTree } from "../pages/productTree/ProductTree";
import { CopyInstructions } from "../pages/Instructions/CopyInstructions";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import {
  Input,
  Label,
  Menu,
  MenuHeader,
  MenuItem,
  MenuMenu,
} from "semantic-ui-react";
import { SelectInstructions } from "../pages/Instructions/SelectInstructions";
import { Dashboard } from "../pages/dashboard/Dashboard";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const defaultInstructions: IInstruction[] = [];

  const context = useContext(ApplicationContext);
  const [loginView, setLoginView] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const [newProduct, setNewProduct] = React.useState(false);
  const [viewProducts, setViewProducts] = React.useState(false);
  const [viewExtras, setViewExtras] = React.useState(false);
  const [viewAddExtras, setViewAddExtras] = React.useState(false);
  const [viewVariations, setViewVariations] = React.useState(false);
  const [viewAddVariation, setViewAddVariation] = React.useState(false);

  const [viewInstructions, setViewInstructions] = React.useState(false);
  const [viewAddInstructions, setViewAddInstructions] = React.useState(false);

  const [viewOptions, setViewOptions] = React.useState(false);
  const [viewAddOptions, setViewAddOptions] = React.useState(false);

  const [showSideBar, setShowSideBar] = React.useState(true);
  const [showMenu, setShowMenu] = React.useState(false);

  const navigate = useNavigate();
  const defaultTableData: ITableData[] = [];

  const handleLoginSuccess = () => {
    setLoginView(false);
    context.ViewPage.setView(ViewPage.home);
    setViewProducts(true);
  };

  const handleRegister = () => {};
  const handleForgotPassword = () => {};

  function toggleRootClass() {
    const current = document.documentElement.getAttribute("data-bs-theme");
    const inverted = current == "dark" ? "light" : "dark";
    document.documentElement.setAttribute("data-bs-theme", inverted);
  }

  function toggleLocalStorage() {
    if (isLight()) {
      localStorage.removeItem("light");
    } else {
      localStorage.setItem("light", "set");
    }
  }

  function isLight() {
    return localStorage.getItem("dark");
  }

  function toggleDark() {}

  if (isLight()) {
    toggleRootClass();
  }

  async function getBranchSettings() {
    setLoading(true);

    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    //console.log(response);

    context.BranchMenu.setMenu(response.menu);
    context.Configuration.setSettings(response.branchSettings);

    //setProducts(context.BranchMenu.getMenu().products);

    setLoading(false);
  }

  useEffect(() => {
    setLoadingError(true);
  }, [context.ApplicationError.hasError()]);

  useEffect(() => {
    //getBranchSettings();
  }, []);

  useEffect(() => {
    //getBranchSettings();
  }, [context.Configuration.invalidate()]);

  function showProducts() {
    navigate("/Products");
  }

  function showDashboard() {
    navigate("/Dashboard");
  }

  function showAddProducts() {
    navigate("/AddProduct");
  }

  function showAllExtras() {
    navigate("/Extras");
  }

  function showAddExtras() {
    navigate("/AddExtra");
  }

  function showVariations() {
    navigate("/Variations");
  }
  function showAddVariations() {
    navigate("/AddVariation");
  }

  function showAddInstructions() {
    navigate("/AddInstruction");
  }

  function showInstructions() {
    navigate("/Instructions");
  }

  function showOptions() {
    navigate("/Options");
  }

  function showAddOptions() {
    navigate("/AddOption");
  }

  function showMultiInstructions() {
    navigate("/MultiInstruction");
  }

  function showProductTreeView() {
    navigate("/TreeView");
  }

  function showCopyInstructions() {
    navigate("/CopyInstructions");
  }

  function toggleSideBar() {
    if (showSideBar) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }

  function toggleMenu() {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }

  const templete3 = (): JSX.Element => (
    <>
      <div id="data-bs-theme=dark"></div>
      <div className="wrapper">
        <aside
          id="sidebar"
          className={showSideBar ? "js-sidebar" : "js-sidebar collapsed"}
        >
          <div className="h-100">
            <div className="sidebar-logo">
              <a href="#">EPOS - Admin</a>
            </div>

            <div style={{ overflowY: "auto", padding: 0, maxHeight: "90vh" }}>
              <li className="sidebar-item">
                <div className="sidebar-link" onClick={() => showDashboard()}>
                  <i className="fa-solid fa-list pe-2"></i>
                  <a>Dashboard</a>
                </div>
              </li>
              <li className="sidebar-item">
                <a
                  className={
                    showMenu ? "sidebar-link collapsed" : "sidebar-link"
                  }
                  onClick={toggleMenu}
                  data-bs-target="#menu"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-file-lines pe-2"></i>
                  Menu
                </a>
                <ul
                  id="menu"
                  className={
                    showMenu
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-link">
                    <div
                      style={{ paddingLeft: 15 }}
                      onClick={() => showProducts()}
                    >
                      <a>Products</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showProducts()}
                    >
                      <a>All</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAddProducts()}
                    >
                      <a>Add</a>
                    </li>
                  </li>
                  <li className="sidebar-link">
                    <div
                      style={{ paddingLeft: 15 }}
                      onClick={() => showAllExtras()}
                    >
                      <a>Extra</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAllExtras()}
                    >
                      <a>All</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAddExtras()}
                    >
                      <a>Add</a>
                    </li>
                  </li>
                  <li className="sidebar-link">
                    <div
                      style={{ paddingLeft: 15 }}
                      onClick={() => showVariations()}
                    >
                      <a>Variations</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showVariations()}
                    >
                      <a>All</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAddVariations()}
                    >
                      <a>Add</a>
                    </li>
                  </li>
                  <li className="sidebar-link">
                    <div
                      style={{ paddingLeft: 15 }}
                      onClick={() => showInstructions()}
                    >
                      <a>Instructions</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showInstructions()}
                    >
                      <a>All</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAddInstructions()}
                    >
                      <a>Add</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showCopyInstructions()}
                    >
                      <a>Copy</a>
                    </li>
                  </li>
                  <li className="sidebar-link">
                    <div style={{ paddingLeft: 15 }} onClick={showOptions}>
                      <a>Options</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showOptions()}
                    >
                      <a>All</a>
                    </li>
                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showAddOptions()}
                    >
                      <a>Add</a>
                    </li>
                  </li>
                  <li className="sidebar-link">
                    <div
                      style={{ paddingLeft: 15 }}
                      onClick={() => showProducts()}
                    >
                      <a>Hierarchy</a>
                    </div>

                    <li
                      className="sidebar-link"
                      style={{ paddingLeft: 25 }}
                      onClick={() => showProductTreeView()}
                    >
                      <a>Tree View</a>
                    </li>
                  </li>
                </ul>
              </li>
            </div>
          </div>
        </aside>
        <div className="main">
          <nav className="navbar navbar-expand px-3 border-bottom">
            <button
              className="btn"
              id="sidebar-toggle"
              type="button"
              onClick={toggleSideBar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse navbar">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    data-bs-toggle="dropdown"
                    className="nav-icon pe-md-0"
                  >
                    {/* <img src="image/profile.jpg" className="avatar img-fluid rounded" alt=""> */}
                    Logout
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="#" className="dropdown-item">
                      Profile
                    </a>
                    <a href="#" className="dropdown-item">
                      Setting
                    </a>
                    <a href="#" className="dropdown-item">
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <main className="content px-3 py-2">
            <div>{pages()}</div>
          </main>
          {/* <a href="#" className="theme-toggle">
            <i className="fa-regular fa-moon" onClick={toggleSideBar}></i>
            <i className="fa-regular fa-sun" onClick={toggleSideBar}></i>
          </a> */}
          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  <p className="mb-0">
                    <a href="#" className="text-muted">
                      <strong>aleaf.co.uk</strong>
                    </a>
                  </p>
                </div>
                <div className="col-6 text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="#" className="text-muted">
                        Contact
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="text-muted">
                        About Us
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="text-muted">
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );

  const templete2 = (): JSX.Element => (
    <>
      <Container>
        <br />
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="#">
            EPOS
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Menu <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Reports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Printers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Organisation
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Configuration
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Locations
                </a>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </li>*/}
            </ul>
            <form className="form-inline my-2 my-lg-0">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              ></input>
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
              >
                Search
              </button>
            </form>
          </div>
        </nav>

        <br />

        <div className="container-fluid">
          <div className="row flex-nowrap">
            <Menu vertical>
              <MenuItem>
                <MenuHeader>Products</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All products"
                    active={viewProducts}
                    onClick={() => showProducts()}
                  ></MenuItem>
                  <MenuItem
                    name="New"
                    active={newProduct}
                    onClick={() => showAddProducts()}
                  ></MenuItem>
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Extras</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All extras"
                    active={viewExtras}
                    onClick={showAllExtras}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddExtras}
                    onClick={showAddExtras}
                  />
                </MenuMenu>
              </MenuItem>
              <MenuItem>
                <MenuHeader>Variation</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Variations"
                    active={viewVariations}
                    onClick={showVariations}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddVariation}
                    onClick={showAddVariations}
                  />
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Instructions</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Instructions"
                    active={viewInstructions}
                    onClick={showInstructions}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddInstructions}
                    onClick={showAddInstructions}
                  ></MenuItem>
                  <MenuItem
                    name="Multi"
                    active={viewAddInstructions}
                    onClick={showMultiInstructions}
                  />
                  <MenuItem
                    name="Copy"
                    active={viewAddInstructions}
                    onClick={showCopyInstructions}
                  />
                </MenuMenu>
              </MenuItem>
              <MenuItem>
                <MenuHeader>Options</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Options"
                    active={viewOptions}
                    onClick={showOptions}
                  />
                  <MenuItem
                    name=""
                    active={viewAddOptions}
                    onClick={showAddOptions}
                  >
                    Add
                  </MenuItem>
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Tree View</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="Hierarchy"
                    active={viewOptions}
                    onClick={showProductTreeView}
                  />
                </MenuMenu>
              </MenuItem>
            </Menu>
            <div className="col py-3">
              <div> {pages()}</div>
            </div>
          </div>
        </div>
      </Container>

      {/* <Container>
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 bg-dark">
              <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a
                  href="/"
                  className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
                >
                  <span className="fs-5 d-none d-sm-inline">
                    <br />
                    EPOS - Admin
                    <br />
                  </span>
                </a>
                <ul
                  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                  id="menu"
                >
                  <li className="nav-item">
                    <a href="#" className="nav-link align-middle px-0">
                      <i className="fs-4 bi-house"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Home</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#submenu1"
                      data-bs-toggle="collapse"
                      className="nav-link px-0 align-middle"
                    >
                      <i className="fs-4 bi-speedometer2"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Dashboard</span>{" "}
                    </a>
                    <ul
                      className="collapse show nav flex-column ms-1"
                      id="submenu1"
                      data-bs-parent="#menu"
                    >
                      <li className="w-100">
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">
                            Item
                          </span> 1{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">
                            Item
                          </span> 2{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0 align-middle">
                      <i className="fs-4 bi-table"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Orders</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#submenu2"
                      data-bs-toggle="collapse"
                      className="nav-link px-0 align-middle "
                    >
                      <i className="fs-4 bi-bootstrap"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Bootstrap</span>
                    </a>
                    <ul
                      className="collapse nav flex-column ms-1"
                      id="submenu2"
                      data-bs-parent="#menu"
                    >
                      <li className="w-100">
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Item</span> 1
                        </a>
                      </li>
                      <li>
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Item</span> 2
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href="#submenu3"
                      data-bs-toggle="collapse"
                      className="nav-link px-0 align-middle"
                    >
                      <i className="fs-4 bi-grid"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Products</span>{" "}
                    </a>
                    <ul
                      className="collapse nav flex-column ms-1"
                      id="submenu3"
                      data-bs-parent="#menu"
                    >
                      <li className="w-100">
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Product</span> 1
                        </a>
                      </li>
                      <li>
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Product</span> 2
                        </a>
                      </li>
                      <li>
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Product</span> 3
                        </a>
                      </li>
                      <li>
                        <a href="#" className="nav-link px-0">
                          {" "}
                          <span className="d-none d-sm-inline">Product</span> 4
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0 align-middle">
                      <i className="fs-4 bi-people"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Customers</span>{" "}
                    </a>
                  </li>
                </ul>
                <hr />
                <div className="dropdown pb-4">
                  <a
                    href="#"
                    className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="https://github.com/mdo.png"
                      alt="hugenerd"
                      width="30"
                      height="30"
                      className="rounded-circle"
                    ></img>
                    <span className="d-none d-sm-inline mx-1">loser</span>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                    <li>
                      <a className="dropdown-item" href="#">
                        New project...
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Settings
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Profile
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col py-3">context area here</div>
          </div>
        </div>
      </Container> */}
    </>
  );

  // const template = (): JSX.Element => (
  //   <>
  //     <div className="container">
  //       <br />
  //       <br />
  //       <div className="row">
  //         <div style={{ width: "30%" }}>
  //           <Menu vertical size="small">
  //             <MenuItem>
  //               <MenuHeader>Products</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="All products"
  //                   active={viewProducts}
  //                   onClick={() => showProducts()}
  //                 ></MenuItem>
  //                 <MenuItem
  //                   name="New"
  //                   active={newProduct}
  //                   onClick={() => showAddProducts()}
  //                 ></MenuItem>
  //               </MenuMenu>
  //             </MenuItem>

  //             <MenuItem>
  //               <MenuHeader>Extras</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="All extras"
  //                   active={viewExtras}
  //                   onClick={showAllExtras}
  //                 />
  //                 <MenuItem
  //                   name="Add"
  //                   active={viewAddExtras}
  //                   onClick={showAddExtras}
  //                 />
  //               </MenuMenu>
  //             </MenuItem>
  //             <MenuItem>
  //               <MenuHeader>Variation</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="All Variations"
  //                   active={viewVariations}
  //                   onClick={showVariations}
  //                 />
  //                 <MenuItem
  //                   name="Add"
  //                   active={viewAddVariation}
  //                   onClick={showAddVariations}
  //                 />
  //               </MenuMenu>
  //             </MenuItem>

  //             <MenuItem>
  //               <MenuHeader>Instructions</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="All Instructions"
  //                   active={viewInstructions}
  //                   onClick={showInstructions}
  //                 />
  //                 <MenuItem
  //                   name="Add"
  //                   active={viewAddInstructions}
  //                   onClick={showAddInstructions}
  //                 ></MenuItem>
  //                 <MenuItem
  //                   name="Multi"
  //                   active={viewAddInstructions}
  //                   onClick={showMultiInstructions}
  //                 />
  //                 <MenuItem
  //                   name="Copy"
  //                   active={viewAddInstructions}
  //                   onClick={showCopyInstructions}
  //                 />
  //               </MenuMenu>
  //             </MenuItem>
  //             <MenuItem>
  //               <MenuHeader>Options</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="All Options"
  //                   active={viewOptions}
  //                   onClick={showOptions}
  //                 />
  //                 <MenuItem
  //                   name=""
  //                   active={viewAddOptions}
  //                   onClick={showAddOptions}
  //                 >
  //                   Add
  //                 </MenuItem>
  //               </MenuMenu>
  //             </MenuItem>

  //             <MenuItem>
  //               <MenuHeader>Tree View</MenuHeader>

  //               <MenuMenu>
  //                 <MenuItem
  //                   name="Hierarchy"
  //                   active={viewOptions}
  //                   onClick={showProductTreeView}
  //                 />
  //               </MenuMenu>
  //             </MenuItem>
  //           </Menu>
  //         </div>
  //         <div style={{ width: "70%" }}> {pages()}</div>
  //       </div>
  //     </div>
  //   </>
  // );

  const pages = (): JSX.Element => (
    <>
      <Routes>
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Products" element={<AllProducts />} />
        <Route path="/AddProduct" element={<AddProduct />} />
        <Route path="/AddProduct/:id" element={<AddProduct />} />
        <Route path="/AddProduct/:id/:type/:nav" element={<AddProduct />} />

        <Route path="/Instructions" element={<Instructions />} />
        <Route path="/AddInstruction" element={<AddInstructions />} />
        <Route path="/MultiInstruction" element={<MultiInstructions />} />
        <Route path="/AddInstruction/:id" element={<AddInstructions />} />
        <Route
          path="/AddInstruction/:id/:type/:nav"
          element={<AddInstructions />}
        />
        <Route path="/CopyInstructions" element={<CopyInstructions />} />
        <Route
          path="/CopyInstructions/:id/:nav"
          element={<CopyInstructions />}
        />
        <Route
          path="/SelectInstructions/:id/:nav"
          element={<SelectInstructions />}
        />

        <Route path="/Options" element={<Options />} />
        <Route path="/AddOption" element={<AddOption />} />
        <Route path="/AddOption/:id" element={<AddOption />} />

        <Route path="/Extras" element={<Extras />} />
        <Route path="/AddExtra" element={<AddExtra />} />
        <Route path="/AddExtra/:id" element={<AddExtra />} />

        <Route path="/Variations" element={<Variations />} />
        <Route path="/AddVariation" element={<AddVariation />} />
        <Route path="/AddVariation/:id" element={<AddVariation />} />

        <Route path="/TreeView" element={<ProductTree />} />
      </Routes>
    </>
  );

  const manager = (): JSX.Element => (
    <>
      {loginView ? (
        <Login
          successful={handleLoginSuccess}
          register={handleRegister}
          forgotPassword={handleForgotPassword}
        />
      ) : (
        <></>
      )}
      {loginView === false ? <>{templete3()}</> : <></>}
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <>
        {loadingError ? (
          <div className="loading-center">
            {/* <img src={loadingImage} alt="Loading" /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Error in communication with service
          </div>
        ) : (
          <div className="loading-center">
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  return <div>{loading ? loadingData() : <>{manager()}</>}</div>;
};
