import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import {
  getReceipts,
  getSettings,
  storeProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IReceipt } from "../../../types/IReceipt";
import loadingImage from "../../../images/loading.gif";

export const Dashboard: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  var today = new Date();

  const [receipts, setReceipts] = useState<IReceipt[]>([]);
  const [refresh, setRefresh] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 1)
  );

  async function getReceiptData() {
    setLoading(true);
    const response = await getReceipts(
      startDate,
      endDate,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setReceipts(response);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
  }

  useEffect(() => {
    getReceiptData();
  }, []);

  function getTotal() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      total = total + element.total;
    }

    return total;
  }

  function paidByCard() {
    let card = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "card") {
        card = card + 1;
      }
    }
    return card;
  }

  function totalCardPayment() {
    let cardTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Card") {
        cardTotal = cardTotal + element.total;
      }
    }
    return cardTotal;
  }

  function totalCashPayment() {
    let cashTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Cash") {
        cashTotal = cashTotal + element.total;
      }
    }
    return cashTotal;
  }

  function eatInCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Eat in") {
        total = total + 1;
      }
    }
    return total;
  }

  function collectionCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Collection") {
        total = total + 1;
      }
    }
    return total;
  }

  //Takeout, Collection, Delivery

  function formatDate(d: Date) {
    var date = d.toISOString().substring(0, 10);
    return date;
  }

  function formatTime(d: Date) {
    return d.toLocaleString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  function onStartChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(startDate.getHours());
    dt.setMinutes(startDate.getMinutes());

    setStartDate(dt);
  }

  function onStartChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(startDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setStartDate(dt);
  }

  function onEndChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(endDate.getHours());
    dt.setMinutes(endDate.getMinutes());

    setEndDate(dt);
  }

  function onEndChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(endDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setEndDate(dt);
  }

  function applyFilter() {
    getReceiptData();
  }

  const dashboard = (): JSX.Element => (
    <>
      <div className="container-fluid">
        <div className="mb-3">
          <h4>Dashboard</h4>
        </div>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Filter
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Report range</h5>
                    <br />
                    <p className="card-text">
                      Start date time
                      <br />
                      <input
                        type="date"
                        style={{ fontSize: 16 }}
                        value={formatDate(startDate)}
                        onChange={(e) => onStartChangeDate(e.target.value)}
                      ></input>
                      <input
                        type="time"
                        id="appt"
                        style={{ fontSize: 16 }}
                        value={formatTime(startDate)}
                        onChange={(e) => onStartChangeTime(e.target.value)}
                        name="appt"
                      ></input>
                      <br />
                      <br />
                      End date time <br />
                      <input
                        type="date"
                        style={{ fontSize: 16 }}
                        value={formatDate(endDate)}
                        onChange={(e) => onEndChangeDate(e.target.value)}
                      ></input>
                      <input
                        type="time"
                        style={{ fontSize: 16 }}
                        value={formatTime(endDate)}
                        onChange={(e) => onEndChangeTime(e.target.value)}
                      ></input>
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={applyFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 d-flex">
            <div className="card flex-fill border-0 illustration">
              <div className="card-body p-0 d-flex flex-fill">
                <div className="row g-0 w-100">
                  <div className="col-12 w-100">
                    <div className="p-3 m-1">
                      <h2 className="text-success">
                        {loading ? (
                          <img
                            src={loadingImage}
                            alt="Loading"
                            width={200}
                            height={40}
                          />
                        ) : (
                          "£" + getTotal().toFixed(2)
                        )}
                      </h2>
                      <p className="mb-0">
                        Card: £{totalCardPayment().toFixed(2)}
                      </p>
                      <p className="mb-0">
                        Cash: £{totalCashPayment().toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex">
            <div className="card flex-fill border-0">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="text-success">
                      {loading ? (
                        <img
                          src={loadingImage}
                          alt="Loading"
                          width={200}
                          height={40}
                        />
                      ) : (
                        receipts.length + " Orders"
                      )}
                    </h2>
                    <p className="mb-0">Eat in: {eatInCount()}</p>
                    <p className="mb-0">Collection: {collectionCount()}</p>
                    <p className="mb-0">Paid by card: {paidByCard()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex">
            <div className="card flex-fill border-0">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="text-success">
                      {loading ? (
                        <img
                          src={loadingImage}
                          alt="Loading"
                          width={200}
                          height={40}
                        />
                      ) : (
                        "0 Calls"
                      )}
                    </h2>
                    <p className="mb-2">New numbers registered</p>
                    <div className="mb-0">
                      <span className="badge text-success me-2">+0%</span>
                      <span className="text-muted">Since Last Month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-0">
          <div className="card-header">
            <h5 className="card-title">Receipts</h5>
            <h6 className="card-subtitle text-muted"></h6>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Total</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>{receipts.map((data) => receiptRow(data))}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  function formatDateForDisplay(d: Date) {
    return (
      d.getDate() +
      "/" +
      d.getMonth() +
      "/" +
      d.getFullYear() +
      " - " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  }

  const receiptRow = (r: IReceipt) => (
    <>
      <tr>
        <td>{r.id}</td>
        <td>{formatDateForDisplay(new Date(r.createdAt))}</td>
        <td>{r.total.toFixed(2)}</td>
        <td>@mdo</td>
      </tr>
    </>
  );
  return (
    <>
      <>{dashboard()}</>
    </>
  );
};
