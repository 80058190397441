import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import {
  addInstruction,
  copyProductInstruction,
  getSettings,
  storeProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IInstruction } from "../../../types/IInstruction";

const defaultProduct: IProduct = {
  id: 0,
  uuid: "",
  parentId: 0,
  description: "",
  imageUrl: "",
  position: 0,
  isCategory: false,
  price: 0,
  qty: 0,
  textColour: "",
  buttonColour: "",
  zone: 0,
  barcode: "",
};

export const SelectInstructions: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const { id } = useParams();
  const { nav } = useParams();

  const [sourceData, setSourceData] = useState<IInstruction[]>([]);
  const [targetData, setTargetData] = useState<IInstruction[]>([]);

  const [sourceHighlighted, setSourceHighlighted] = useState<string[]>([]);
  const [targetHighlighted, setTargetHighlighted] = useState<string[]>([]);

  const [product, setProduct] = useState(defaultProduct);
  const [saving, setSaving] = useState(false);

  const [refresh, setRefresh] = useState("");

  const navigate = useNavigate();

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setSourceData(response.menu.instructions);
    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    selectedProduct(Number(id));

    const selected = sourceData.filter((f) => f.productId === product.id);
    setTargetData(selected);
  }, [sourceData]);

  function selectedProduct(id: number) {
    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    setProduct(product);
  }

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function copyInstruction() {
    setSaving(true);

    const saved = addInstruction(
      targetData,
      product.id,
      context.AuthenticatedUser.getToken()
    );

    if (!saved) {
      context.ApplicationError.setHasError(true);
      return;
    }

    await sleep(5000);

    if (nav !== undefined) {
      navigate("/TreeView");
      return;
    }

    navigate("/Instructions");
  }

  function isSourceRowActive(uuid: string) {
    let found = false;
    for (let i = 0; i < sourceHighlighted.length; i++) {
      if (sourceHighlighted[i] === uuid) {
        found = true;
      }
    }
    return found;
  }
  function toggleSourceSelected(id: string) {
    let found = false;

    for (let i = 0; i < sourceHighlighted.length; i++) {
      if (sourceHighlighted[i] === id) {
        found = true;
      }
    }

    if (found === true) {
      let arr = sourceHighlighted.filter((item) => item !== id);
      setSourceHighlighted(arr);
    }

    if (found === false) {
      setSourceHighlighted([...sourceHighlighted, id]);
    }
  }

  function isTargetRowActive(uuid: string) {
    let found = false;
    for (let i = 0; i < targetHighlighted.length; i++) {
      if (targetHighlighted[i] === uuid) {
        found = true;
      }
    }
    return found;
  }

  function toggleTargetSelected(id: string) {
    let found = false;

    for (let i = 0; i < targetHighlighted.length; i++) {
      if (targetHighlighted[i] === id) {
        found = true;
      }
    }

    if (found === true) {
      let arr = targetHighlighted.filter((item) => item !== id);
      setTargetHighlighted(arr);
    }

    if (found === false) {
      setTargetHighlighted([...targetHighlighted, id]);
    }
  }

  function onPositionChanged(uuid: string, newPos: string) {
    const foundInstruction = targetData.find((element) => {
      return element.uuid === uuid;
    });

    if (foundInstruction === undefined) {
      return;
    }

    foundInstruction.position = Number(newPos);
    setRefresh(GenerateGUID());
  }

  function onPriceChange(uuid: string, value: string) {
    const foundInstruction = targetData.find((element) => {
      return element.uuid === uuid;
    });

    if (foundInstruction === undefined) {
      return;
    }

    foundInstruction.price = Number(value);
  }

  function findInstructionById(uuid: string) {
    return sourceData.find((element) => {
      return element.uuid === uuid;
    });
  }

  function add() {
    let selected: IInstruction[] = [];

    for (let i = 0; i < sourceHighlighted.length; i++) {
      const foundInstruction = findInstructionById(sourceHighlighted[i]);

      if (foundInstruction === undefined) {
        continue;
      }

      const Instruction: IInstruction = JSON.parse(
        JSON.stringify(foundInstruction)
      );

      Instruction.productId = product.id;

      selected.push(Instruction);
    }

    setTargetData([...targetData, ...selected]);
  }

  function remove() {
    let data: IInstruction[] = [];

    for (let i = 0; i < targetData.length; i++) {
      let found = false;
      for (let j = 0; j < targetHighlighted.length; j++) {
        if (targetData[i].uuid === targetHighlighted[j]) {
          found = true;
          break;
        }
      }

      if (!found) {
        data.push(targetData[i]);
      }
    }

    setTargetData(data);
  }

  const copy = (): JSX.Element => (
    <>
      <div className="container">
        <h1>Instructions</h1>
        <h4>{product.description}</h4>
        <hr />
        <div>
          <div
            style={{
              paddingLeft: 20,
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div className="row">
              <div style={{ width: "44%" }}>
                <div style={{ height: "60vh", overflowY: "auto" }}>
                  <Table unstackable selectable color={"blue"}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {sourceData
                        .filter(
                          (f) => f.productId === 1 && f.isCommon === false
                        )
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                        .map((data) => (
                          <Table.Row
                            active={isSourceRowActive(data.uuid)}
                            onClick={() => toggleSourceSelected(data.uuid)}
                          >
                            <Table.Cell>{data.description}</Table.Cell>
                            <Table.Cell>{data.price.toFixed(2)}</Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{ padding: "20px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-lg"
                  style={{ width: "40px" }}
                  onClick={() => add()}
                >
                  {">"}
                </button>

                <br />
                <button
                  type="button"
                  className="btn btn-outline-primary btn-lg"
                  style={{ width: "40px" }}
                  onClick={() => remove()}
                >
                  X
                </button>
              </div>
              <div style={{ width: "44%" }}>
                <div>
                  <div style={{ height: "60vh", overflowY: "auto" }}>
                    <Table unstackable selectable color={"blue"}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Position</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell>Price</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {targetData
                          .sort((a, b) => (a.position > b.position ? 1 : -1))
                          .map((data) => (
                            <Table.Row
                              active={isTargetRowActive(data.uuid)}
                              onClick={() => toggleTargetSelected(data.uuid)}
                            >
                              <Table.Cell>
                                <div style={{ width: "60px" }}>
                                  <Form.Control
                                    type="text"
                                    defaultValue={data.position}
                                    onBlur={(e) =>
                                      onPositionChanged(
                                        data.uuid,
                                        e.target.value
                                      )
                                    }
                                    key={data.uuid}
                                  />
                                </div>
                              </Table.Cell>
                              <Table.Cell>{data.description}</Table.Cell>
                              <Table.Cell>
                                <div style={{ width: "60px" }}>
                                  <Form.Control
                                    type="text"
                                    defaultValue={data.price.toFixed(2)}
                                    onBlur={(e) =>
                                      onPriceChange(data.uuid, e.target.value)
                                    }
                                    key={data.uuid}
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <Button
          disabled={saving}
          variant="primary"
          size="lg"
          onClick={() => copyInstruction()}
        >
          {saving ? "Saving..." : "Set instructions"}
        </Button>

        <br />
        <br />
      </div>
    </>
  );

  return (
    <>
      <>{copy()}</>
    </>
  );
};
