/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
//import { authenticate } from "../../services/Authenticate";
import ApplicationContext from "../../context/ApplicationContext";

//import "./styles/bootstrap.css";
// import "./styles/utility.css";
// import "./styles/typography.css";
import { Button } from "react-bootstrap";
import { ILoginProps } from "./ILoginProps";
import { authenticate } from "../../services/Authenticate";
import { IAuth } from "../../types/IAuth";

import "./Style.css";

export const Login: React.FC<ILoginProps> = (
  props: ILoginProps
): JSX.Element => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(
    "mobile number/Password incorrect"
  );

  const context = useContext(ApplicationContext);

  async function handleLogin(e: any) {
    e.preventDefault();
    var auth = await authenticate(username, password);

    if (!auth) {
      setErrorMsg("Login failed");
      setShowError(true);
      return;
    }

    if (auth.status !== 200) {
      setErrorMsg("Login failed");
      setShowError(true);
      return;
    }

    const data: IAuth = auth.data;
    context.AuthenticatedUser.setUser(data.user);
    context.AuthenticatedUser.setToken(data.tokenValue);

    sleep(5000);

    props.successful();

    setShowError(false);
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  function handleRegister() {
    props.register();
  }

  function handleForgotPassword() {
    props.forgotPassword();
  }

  function onEnterHit(e: any) {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  }

  const login2 = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            width: "300px",
          }}
        >
          {/* <img className="mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"> */}
          <h1 className="h3 mb-3 fw-normal">EPOS Admin</h1>

          <div className="form-group">
            {showError ? (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setUsername(e.target.value)}
            ></input>
            <label htmlFor="floatingInput">Username</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <div className="form-check text-start my-3">
            <input
              className="form-check-input"
              type="checkbox"
              value="remember-me"
            ></input>
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Remember me
            </label>
          </div>
          <button
            className="btn btn-primary w-100 py-2"
            type="submit"
            onClick={handleLogin}
          >
            Sign in
          </button>

          <p className="mt-5 mb-3 text-body-secondary">
            &copy; powered by aleaf.co.uk
          </p>
        </div>
      </>
    );
  };

  const login = (): JSX.Element => {
    return (
      <form onKeyUp={onEnterHit}>
        <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
          <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
            <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
              EPOS
            </div>
            <div className="tx-center mg-b-60">Mobile</div>
            <div className="form-group">
              {showError ? (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="inputMobileNumber"
                type="text"
                placeholder="email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="inputPassword"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="link"
                className="tx-info tx-12 d-block mg-t-10"
                onClick={handleForgotPassword}
              >
                Forgot password?
              </Button>
            </div>
            <Button
              variant="primary"
              className="btn btn-primary btn-block"
              onClick={handleLogin}
            >
              Login
            </Button>

            <p className="mt-5 mb-3 text-body-secondary">&copy; 2017–2024</p>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {login2()}
    </div>
  );
};
