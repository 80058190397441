import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Dropdown, FormField, Table } from "semantic-ui-react";
import { IProductOption } from "../../../../types/IProductOption";
import ApplicationContext from "../../../../context/ApplicationContext";
import { getSettings } from "../../../../services/Service";
import { IOption } from "../../../../types/IOption";
import { IExtra } from "../../../../types/IExtra";
import { IVariation } from "../../../../types/IVariation";
import { GenerateGUID } from "../../../../helpers/Helpers";

export interface IAddOptionProps {
  close(): void;
  show: boolean;
  productOption: IProductOption[];
  editOptionUuid: string;
}

interface ITableData {
  uuid: string;
  id: number;
  position: number;
  description: string;
  price: number;
}

const defaultVariations: IVariation[] = [];
const defaultExtras: IExtra[] = [];
const defaultOption: IOption = {
  id: 0,
  uuid: "",
  productId: 0,
  description: "",
  image: "",
  position: 0,
  price: 0,
  qty: 0,
};

const defaultProductOption: IProductOption = {
  id: 0,
  uuid: "",
  position: 0,
  option: defaultOption,
  variations: defaultVariations,
  extras: defaultExtras,
};

export const ModalOptions: React.FC<IAddOptionProps> = (
  props: IAddOptionProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  //option details
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [price, setPrice] = useState("");

  const [isValidDescription, setIsInvalidDescription] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);

  //variations
  const [variations, setVariations] = useState<ITableData[]>([]);
  const [selectedVariations, setSelectedVariations] = useState<ITableData[]>(
    []
  );
  const [highlightedVariations, setHighlightedVariations] = useState<string[]>(
    []
  );
  const [selectedVariationRow, setSelectedVariationRow] = useState("");

  //Extras
  const [extras, setExtras] = useState<ITableData[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<ITableData[]>([]);
  const [selectedExtrasRow, setSelectedExtrasRow] = useState("");
  const [highlightedExtras, setHighlightedExtras] = useState<string[]>([]);

  function clearAll() {
    setDescription("");
    setImageUrl("");
    setPrice((0).toFixed(2));
    setSelectedVariations([]);
    setSelectedExtras([]);
  }

  function inEditMode() {
    if (props.editOptionUuid.length === 0) {
      clearAll();
      return;
    }

    let pOptions;

    for (let i = 0; i < props.productOption.length; i++) {
      if (props.productOption[i].uuid === props.editOptionUuid) {
        pOptions = props.productOption[i];
      }
    }

    if (pOptions === undefined) {
      return;
    }

    setDescription(pOptions.option.description);
    setImageUrl(pOptions.option.image);
    setPrice(pOptions.option.price.toFixed(2));

    let v: ITableData[] = [];

    for (let i = 0; i < pOptions.variations.length; i++) {
      const d: ITableData = {
        uuid: pOptions.variations[i].uuid,
        description: pOptions.variations[i].description,
        id: pOptions.variations[i].id,
        position: pOptions.variations[i].position,
        price: pOptions.variations[i].price,
      };

      v.push(d);
    }

    let e: ITableData[] = [];

    for (let i = 0; i < pOptions.extras.length; i++) {
      const d: ITableData = {
        uuid: pOptions.extras[i].uuid,
        description: pOptions.extras[i].description,
        id: pOptions.extras[i].id,
        position: pOptions.extras[i].position,
        price: pOptions.extras[i].price,
      };

      e.push(d);
    }

    setSelectedVariations(v);
    setSelectedExtras(e);
  }

  useEffect(() => {
    inEditMode();
  }, [props.editOptionUuid]);

  function onVariationPositionChanged(uuid: string, newPos: string) {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedVariations));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === uuid) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    data[idx].position = Number(newPos);
    setSelectedVariations(data);
  }

  function onExtraPositionChanged(uuid: string, newPos: string) {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedExtras));

    let idx = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === uuid) {
        idx = i;
      }
    }

    data[idx].position = Number(newPos);
    setSelectedExtras(data);
  }

  function onVariationPriceChange(uuid: string, value: string) {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedVariations));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === uuid) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    data[idx].price = Number(value);
    setSelectedVariations(data);
  }

  function onPriceChange(uuid: string, value: string) {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedExtras));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === uuid) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    data[idx].price = Number(value);
    setSelectedExtras(data);
  }

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    let v: ITableData[] = [];
    response.menu.variations.forEach((d) => {
      if (d.optionId === 0) {
        const d1: ITableData = {
          id: d.id,
          description: d.description,
          price: d.price,
          uuid: GenerateGUID(),
          position: d.id,
        };
        v.push(d1);
      }
    });
    setVariations(v);

    let e: ITableData[] = [];
    response.menu.extras.forEach((d) => {
      if (d.optionId === 0) {
        const d1: ITableData = {
          id: d.id,
          description: d.description,
          price: d.price,
          uuid: GenerateGUID(),
          position: d.id,
        };
        e.push(d1);
      }
    });
    setExtras(e);

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, [props.show]);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    //check position
    // const pos = parseFloat(position);
    // if (position.length === 0 || isNaN(pos)) {
    //   setIsInvalidPosition(true);
    //   valid = false;
    // } else {
    //   setIsInvalidPosition(false);
    // }

    //price
    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    //qty
    // const q = parseFloat(qty);
    // if (qty.length === 0 || isNaN(q)) {
    //   setIsInvalidQty(true);
    //   valid = false;
    // } else {
    //   setIsInvalidQty(false);
    // }

    return valid;
  }

  function arraymove(arr: any, from: number, to: number) {
    var element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
  }

  function moveUpExtra() {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedExtras));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === selectedExtrasRow) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    if (idx === 0) {
      return;
    }

    data[idx].position = idx - 1;

    arraymove(data, idx, idx - 1);
    setSelectedExtras([...data]);
  }

  function deleteExtraSelected() {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedExtras));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === selectedExtrasRow) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    data.splice(idx, 1);
    setSelectedExtras([...data]);
  }

  function deleteVariationSelected() {
    let data: ITableData[] = JSON.parse(JSON.stringify(selectedVariations));

    let idx = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === selectedVariationRow) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    data.splice(idx, 1);
    setSelectedVariations([...data]);
  }

  function isVariationRowActive(uuid: string) {
    let found = false;
    for (let i = 0; i < highlightedVariations.length; i++) {
      if (highlightedVariations[i] === uuid) {
        found = true;
      }
    }
    return found;
  }

  function addVariationToHighlight(id: string) {
    let found = false;
    for (let i = 0; i < highlightedVariations.length; i++) {
      if (highlightedVariations[i] === id) {
        found = true;
      }
    }

    if (found === true) {
      let arr = highlightedVariations.filter((item) => item !== id);
      setHighlightedVariations(arr);
    }

    if (found === false) {
      setHighlightedVariations([...highlightedVariations, id]);
    }
  }

  function isExtraRowActive(uuid: string) {
    let found = false;
    for (let i = 0; i < highlightedExtras.length; i++) {
      if (highlightedExtras[i] === uuid) {
        found = true;
      }
    }
    return found;
  }

  function addExtraToHighlight(id: string) {
    let found = false;
    for (let i = 0; i < highlightedExtras.length; i++) {
      if (highlightedExtras[i] === id) {
        found = true;
      }
    }

    if (found === true) {
      let arr = highlightedExtras.filter((item) => item !== id);
      setHighlightedExtras(arr);
    }

    if (found === false) {
      setHighlightedExtras([...highlightedExtras, id]);
    }
  }

  function addVariation() {
    let data: ITableData[] = [];
    for (let i = 0; i < highlightedVariations.length; i++) {
      for (let j = 0; j < variations.length; j++) {
        if (highlightedVariations[i] === variations[j].uuid) {
          const d: ITableData = {
            uuid: GenerateGUID(),
            description: variations[j].description,
            id: variations[j].id,
            position: variations[j].position,
            price: variations[j].price,
          };

          data.push(d);
        }
      }
    }
    setSelectedVariations([...selectedVariations, ...data]);
  }

  function addExtras() {
    let data: ITableData[] = [];
    for (let i = 0; i < highlightedExtras.length; i++) {
      for (let j = 0; j < extras.length; j++) {
        if (highlightedExtras[i] === extras[j].uuid) {
          const d: ITableData = {
            uuid: GenerateGUID(),
            description: extras[j].description,
            id: extras[j].id,
            position: extras[j].position,
            price: extras[j].price,
          };
          data.push(d);
        }
      }
    }
    setSelectedExtras([...selectedExtras, ...data]);
  }

  const modalOptions = (): JSX.Element => {
    return (
      <Modal
        show={props.show}
        onHide={() => props.close()}
        size="xl"
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Description">
                <div className="container">
                  <Form>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Description
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          isInvalid={isValidDescription}
                          required={true}
                          type="text"
                          placeholder="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Image URL
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          placeholder="Image URL"
                          value={imageURL}
                          onChange={(e) => setImageUrl(e.target.value)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Price
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          placeholder="Price"
                          isInvalid={isValidPrice}
                          value={price}
                          defaultValue={(0).toFixed(2)}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Variations">
                <div>
                  <div
                    style={{
                      paddingLeft: 20,
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <div className="row">
                      <div style={{ width: "44%" }}>
                        <div style={{ height: "60vh", overflowY: "scroll" }}>
                          <Table unstackable selectable color={"blue"}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Price</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {variations.map((data) => (
                                <Table.Row
                                  active={isVariationRowActive(data.uuid)}
                                  onClick={() =>
                                    addVariationToHighlight(data.uuid)
                                  }
                                >
                                  <Table.Cell>{data.description}</Table.Cell>
                                  <Table.Cell>
                                    {data.price.toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </div>
                      <div style={{ padding: "20px" }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                          onClick={() => addVariation()}
                        >
                          +
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                        >
                          -
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                        >
                          u
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                        >
                          d
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                          onClick={() => deleteVariationSelected()}
                        >
                          x
                        </button>
                      </div>
                      <div style={{ width: "44%" }}>
                        <div>
                          <div
                            style={{
                              overflowY: "scroll",
                              maxHeight: "60vh",
                            }}
                          >
                            <Table unstackable selectable color={"blue"}>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Position</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Description
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Price</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {selectedVariations
                                  .sort((a, b) =>
                                    a.position > b.position ? 1 : -1
                                  )
                                  .map((data) => (
                                    <Table.Row
                                      active={
                                        data.uuid === selectedVariationRow
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        setSelectedVariationRow(data.uuid)
                                      }
                                    >
                                      <Table.Cell>
                                        <div style={{ width: "60px" }}>
                                          <Form.Control
                                            type="text"
                                            defaultValue={data.position}
                                            onBlur={(e) =>
                                              onVariationPositionChanged(
                                                data.uuid,
                                                e.target.value
                                              )
                                            }
                                            key={data.uuid}
                                          />
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        {data.description}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div style={{ width: "60px" }}>
                                          <Form.Control
                                            type="text"
                                            defaultValue={data.price.toFixed(2)}
                                            onBlur={(e) =>
                                              onVariationPriceChange(
                                                data.uuid,
                                                e.target.value
                                              )
                                            }
                                            key={data.uuid}
                                          />
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="contact" title="Extras">
                <div>
                  <div
                    style={{
                      paddingLeft: 20,
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <div className="row">
                      <div style={{ width: "44%" }}>
                        <div
                          style={{
                            overflowY: "scroll",
                            maxHeight: "60vh",
                          }}
                        >
                          <Table unstackable selectable color={"blue"}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Price</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {extras.map((data) => (
                                <Table.Row
                                  active={isExtraRowActive(data.uuid)}
                                  onClick={() => addExtraToHighlight(data.uuid)}
                                >
                                  <Table.Cell>{data.description}</Table.Cell>
                                  <Table.Cell>
                                    {data.price.toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </div>
                      <div style={{ padding: "20px" }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                          onClick={() => addExtras()}
                        >
                          +
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                        >
                          -
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                          onClick={() => moveUpExtra()}
                        >
                          u
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                        >
                          d
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          style={{ width: "40px" }}
                          onClick={() => deleteExtraSelected()}
                        >
                          x
                        </button>
                      </div>
                      <div style={{ width: "44%" }}>
                        <div>
                          <div
                            style={{
                              overflowY: "scroll",
                              maxHeight: "60vh",
                            }}
                          >
                            <Table unstackable selectable color={"blue"}>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Position</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Description
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Price</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {selectedExtras
                                  .sort((a, b) =>
                                    a.position > b.position ? 1 : -1
                                  )
                                  .map((data, idx) => (
                                    <Table.Row
                                      active={
                                        data.uuid === selectedExtrasRow
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        setSelectedExtrasRow(data.uuid)
                                      }
                                    >
                                      <Table.Cell>
                                        <div style={{ width: "60px" }}>
                                          <Form.Control
                                            type="text"
                                            defaultValue={data.position}
                                            onBlur={(e) =>
                                              onExtraPositionChanged(
                                                data.uuid,
                                                e.target.value
                                              )
                                            }
                                            key={data.uuid}
                                          />
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        {data.description}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div style={{ width: "60px" }}>
                                          <Form.Control
                                            type="text"
                                            defaultValue={data.price.toFixed(2)}
                                            onBlur={(e) =>
                                              onPriceChange(
                                                data.uuid,
                                                e.target.value
                                              )
                                            }
                                            key={data.uuid}
                                          />
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="success"
              className="modal-padding"
              onClick={() => saveAndClose()}
            >
              Save & Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function saveAndClose() {
    //variations
    let sVariations: IVariation[] = [];
    for (let i = 0; i < selectedVariations.length; i++) {
      const v: IVariation = {
        id: 0,
        uuid: GenerateGUID(),
        optionId: 0,
        description: selectedVariations[i].description,
        image: "",
        position: selectedVariations[i].position,
        price: selectedVariations[i].price,
        qty: 0,
      };

      sVariations.push(v);
    }

    //extras
    let sExtras: IExtra[] = [];
    for (let i = 0; i < selectedExtras.length; i++) {
      const e: IExtra = {
        uuid: GenerateGUID(),
        id: 0,
        optionId: 0,
        description: selectedExtras[i].description,
        image: "",
        position: selectedExtras[i].position,
        price: selectedExtras[i].price,
        qty: 1,
      };
      sExtras.push(e);
    }

    let o: IOption = {
      id: 0,
      uuid: GenerateGUID(),
      productId: 0,
      description: description,
      image: imageURL,
      position: 0,
      price: parseFloat(price),
      qty: 0,
    };

    let po: IProductOption = {
      id: 0,
      uuid: GenerateGUID(),
      position: props.productOption.length + 1,
      option: o,
      variations: sVariations,
      extras: sExtras,
    };

    if (props.editOptionUuid.length === 0) {
      props.productOption.push(po);
    } else {
      for (let i = 0; i < props.productOption.length; i++) {
        if (props.productOption[i].uuid === props.editOptionUuid) {
          po.uuid = props.editOptionUuid;
          props.productOption[i] = po;
        }
      }
    }

    props.close();
    clearAll();
  }

  return (
    <>
      <>{modalOptions()}</>
    </>
  );
};
