import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import {
  copyProductInstruction,
  getSettings,
  storeProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IInstructionCopier } from "../../../types/IInstructionCopier";

export interface ITableData {
  uuid: string;
  id: number;
  position: number;
  description: string;
  price: number;
}

const defaultProduct: IProduct = {
  id: 0,
  uuid: "",
  parentId: 0,
  description: "",
  imageUrl: "",
  position: 0,
  isCategory: false,
  price: 0,
  qty: 0,
  textColour: "",
  buttonColour: "",
  zone: 0,
  barcode: "",
};

export const CopyInstructions: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [product, setProduct] = useState(defaultProduct);

  const { id } = useParams();
  const { nav } = useParams();

  const [sourceData, setSourceData] = useState<ISearchData[]>([]);
  const [targetData, setTargetData] = useState<ISearchData[]>([]);

  const [sourceValue, setSourceValue] = useState("Home");
  const [targetValue, setTargetValue] = useState("Home");

  const [saving, setSaving] = useState(false);

  const [productId, setProductId] = useState(1);
  const [targetProductId, setTargetProductId] = useState(1);

  const navigate = useNavigate();

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    let searchData: ISearchData[] = [];
    response.menu.products.forEach((p) => {
      const d: ISearchData = {
        productId: p.id,
        value: p.id.toString(),
        key: p.id.toString(),
        text: p.description + " (" + p.id + ")",
        barcode: "",
      };
      searchData.push(d);
    });

    setSourceData(searchData);
    setTargetData(searchData);

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    defaultSelectedProduct(Number(id));
  }, []);

  function defaultSelectedProduct(id: number) {
    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    setSourceValue(product.description);
    setProductId(product.id);
  }

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSourceValue(found.description);
    setProductId(found.id);
  }

  function onTargetSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setTargetValue(found.description);
    setTargetProductId(found.id);
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function copyInstruction() {
    const icopy: IInstructionCopier = {
      sourceProductId: productId,
      targetProductId: targetProductId,
    };

    const saved = copyProductInstruction(
      icopy,
      context.AuthenticatedUser.getToken()
    );

    if (!saved) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.Data.setRefreshUuId(GenerateGUID());

    setSaving(true);

    await sleep(2000);

    if (nav !== undefined) {
      navigate("/TreeView");
      return;
    }

    navigate("/Instructions");
  }

  const sourceProduct = () => (
    <>
      <Dropdown
        button
        fluid
        options={sourceData}
        value={sourceValue}
        defaultSearchQuery={sourceValue}
        searchQuery={sourceValue}
        text={sourceValue}
        onChange={onProductSelected}
        basic={true}
        onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  const targetProduct = () => (
    <>
      <Dropdown
        button
        fluid
        options={targetData}
        value={targetValue}
        defaultSearchQuery={targetValue}
        searchQuery={targetValue}
        text={targetValue}
        onChange={onTargetSelected}
        basic={true}
        onClick={onTargetSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  const copy = (): JSX.Element => (
    <>
      <div className="container">
        <h1>Copy instructions</h1>
        <hr />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Source
            </Form.Label>
            <Col sm={10}>{sourceProduct()}</Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Target
            </Form.Label>
            <Col sm={10}>{targetProduct()}</Col>
          </Form.Group>
          <br />
          <br />
        </Form>
      </div>

      <Button
        disabled={saving}
        variant="success"
        onClick={() => copyInstruction()}
      >
        {saving ? "Saving..." : "Copy instructions"}
      </Button>

      <br />
      <br />
    </>
  );

  return (
    <>
      <>{copy()}</>
    </>
  );
};
