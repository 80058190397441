import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Dropdown, FormField, Table } from "semantic-ui-react";
import { getSettings, storeOption } from "../../../services/Service";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import { INewOption } from "../../../types/INewOption";
import { ModalOptions } from "./Modals/ModalOptions";
import { IProductOption } from "../../../types/IProductOption";

export interface IAddProductProps {
  showOptions(): void;
}

export interface ITableData {
  uuid: string;
  id: number;
  position: number;
  description: string;
  price: number;
}

const defaultProductOption: IProductOption[] = [];

const defaultNewOption: INewOption = {
  id: 0,
  uuid: "",
  productId: 0,
  description: "",
  image: "",
  position: 0,
  price: 0,
  productOptions: defaultProductOption,
};

export const AddOption: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [option, setOption] = useState<INewOption>(defaultNewOption);

  const [searchData, setSearchData] = useState<ISearchData[]>([]);
  const [searchValue, setSearchValue] = useState("Home");

  //options
  const [showModalOptions, setShowModalOptions] = useState(false);
  const [editOptionUuId, setEditOptionUuId] = useState("");

  const [forceRefresh, setForceRefresh] = useState("");

  //selected values
  const [productId, setProductId] = useState(1);
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [position, setPosition] = useState("");
  const [price, setPrice] = useState("");
  const [qty, setQty] = useState("");

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    // products
    let searchData: ISearchData[] = [];
    response.menu.products.forEach((p) => {
      const d: ISearchData = {
        productId: p.id,
        value: p.id.toString(),
        key: p.id.toString(),
        text: p.description,
        barcode: "",
      };
      searchData.push(d);
    });
    setSearchData(searchData);

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSearchValue(found.description);
    setProductId(found.id);
  }

  function validateMe() {
    let valid = true;
    return valid;
  }

  function saveProduct() {
    const valid = validateMe();

    if (valid === false) {
      return;
    }

    option.id = 0;
    option.productId = productId;
    option.description = description;
    option.image = imageURL;

    const nOption: INewOption = {
      id: 0,
      productId: productId,
      description: "description",
      image: "imageURL",
      position: 0,
      price: 0,
      uuid: uuidv4(),
      productOptions: option.productOptions,
    };

    console.log(JSON.stringify(nOption));
    const saved = storeOption(nOption, context.AuthenticatedUser.getToken());

    if (!saved) {
      //show error dialog
      context.ApplicationError.addError("Storing option failed");
      context.ApplicationError.setHasError(true);
      return;
    }
  }

  const searchProduct = () => (
    <>
      <Dropdown
        button
        //className="icon"
        fluid
        //labeled
        //icon="world"
        options={searchData}
        //search
        //clearable={true}
        value={searchValue}
        //defaultSearchQuery={searchValue}
        //searchQuery={searchValue}
        text={searchValue}
        //onSearchChange={handleOnSearchChange}
        onChange={onProductSelected}
        basic={true}
        onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  const add = (): JSX.Element => (
    <>
      <div className="container">
        <h1>Add Option</h1>
        <hr />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Product
            </Form.Label>
            <Col sm={10}>{searchProduct()}</Col>
          </Form.Group>
          <br />
          <h4>Options</h4>
          <hr />

          <Button
            variant="outline-primary"
            onClick={() => setShowModalOptions(true)}
          >
            Add Options
          </Button>
          <br />
          <br />

          {option.productOptions
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((data) => (
              <>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      {data.option.description} (£{data.option.price.toFixed(2)}
                      )
                    </Card.Title>
                    <Card.Text>
                      <h5>Variations</h5>
                      <Table selectable color={"blue"}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Position</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {data.variations
                            .filter((f) => f.optionId === 0)
                            .sort((a, b) => (a.position > b.position ? 1 : -1))
                            .map((v) => (
                              <Table.Row>
                                <Table.Cell>{v.position}</Table.Cell>
                                <Table.Cell>{v.description}</Table.Cell>
                                <Table.Cell>{v.price.toFixed(2)}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                      <br />
                      <h5>Extras</h5>
                      <Table selectable color={"blue"}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Position</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {data.extras
                            .sort((a, b) => (a.position > b.position ? 1 : -1))
                            .map((e) => (
                              <Table.Row>
                                <Table.Cell>{e.position}</Table.Cell>
                                <Table.Cell>{e.description}</Table.Cell>
                                <Table.Cell>{e.price.toFixed(2)}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => editOption(data.uuid)}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => deleteOption(data.uuid)}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
                <br />
              </>
            ))}

          <br />
          <br />
        </Form>
      </div>

      <Button variant="success" onClick={() => saveProduct()}>
        Add Option
      </Button>

      <br />
      <br />
    </>
  );

  function editOption(uuid: string) {
    setEditOptionUuId(uuid);
    setShowModalOptions(true);
  }

  function deleteOption(uuid: string) {
    let idx = -1;
    for (let i = 0; i < option.productOptions.length; i++) {
      if (option.productOptions[i].uuid === uuid) {
        idx = i;
      }
    }

    if (idx === -1) {
      return;
    }

    option.productOptions.splice(idx, 1);
    setForceRefresh(uuidv4());
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  function close() {
    setEditOptionUuId("");
    setShowModalOptions(false);
  }

  return (
    <>
      <>{add()}</>
      <>
        <ModalOptions
          close={close}
          productOption={option.productOptions}
          editOptionUuid={editOptionUuId}
          show={showModalOptions}
        />
      </>
    </>
  );
};
