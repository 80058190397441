import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { IProduct } from "../../../types/IProduct";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteInstruction,
  deleteOption,
  deleteProduct,
  getSettings,
} from "../../../services/Service";

import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import "../../../../node_modules/react-simple-tree-menu/dist/main.css";

import {
  Button,
  Container,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Add as AddProduct } from "./controls/product/Add";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IOption } from "../../../types/IOption";
import { IVariation } from "../../../types/IVariation";
import { IExtra } from "../../../types/IExtra";
import { Dropdown, Menu } from "semantic-ui-react";

export interface IAllProductsProps {
  editId(id: number): void;
}

export interface INode {
  key: string;
  label: string;
  url: string;
  nodes: INode[];
  product: IProduct | undefined;
}

export const ProductTree: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [treeNodes, setTreeNode] = useState<INode[]>([]);
  const [refresh, setRefresh] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const refreshValue = context.Data.getRefreshUuId();

  const [showAddProduct, setShowAddProduct] = useState(false);

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);

    const p = context.BranchMenu.getMenu().products.sort((a, b) =>
      a.position > b.position ? 1 : -1
    );

    setProducts(p);
    setRefresh(GenerateGUID);
  }

  function fillExtras(nodes: INode[], id: number) {
    const d: IExtra[] = context.BranchMenu.getMenu().extras.filter(
      (d) => d.optionId === id
    );

    const data = d.sort((a, b) => (a.position > b.position ? 1 : -1));

    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid.toString(),
        label:
          "e: " + data[i].description + " (" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }
  function fillVariations(nodes: INode[], id: number) {
    const d: IVariation[] = context.BranchMenu.getMenu().variations.filter(
      (d) => d.optionId === id
    );

    const data = d.sort((a, b) => (a.position > b.position ? 1 : -1));

    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "v: " + data[i].description + " (" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }

  function fillOptions(nodes: INode[], id: number) {
    const d: IOption[] = context.BranchMenu.getMenu().options.filter(
      (d) => d.productId === id
    );

    const data = d.sort((a, b) => (a.position > b.position ? 1 : -1));

    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "o: " + data[i].description + " (" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      fillVariations(n.nodes, data[i].id);
      fillExtras(n.nodes, data[i].id);
      nodes.push(n);
    }
  }

  function fillInstructions(nodes: INode[], id: number) {
    const d = context.BranchMenu.getMenu().instructions.filter(
      (d) => d.productId === id
    );

    const data = d.sort((a, b) => (a.position > b.position ? 1 : -1));

    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "instruction: " +
          data[i].description +
          " (" +
          data[i].price.toFixed(2) +
          ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }
  function fillChild(nodes: INode[], parentId: number) {
    const data = products.filter((d) => d.parentId === parentId);
    if (data.length === 0) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          data[i].description +
          " (" +
          data[i].price.toFixed(2) +
          ") " +
          data[i].id,
        url: "",
        nodes: [],
        product: data[i],
      };

      nodes.push(n);

      fillInstructions(n.nodes, data[i].id);
      fillOptions(n.nodes, data[i].id);

      fillChild(n.nodes, data[i].id);
    }
  }

  function buildTreeData() {
    let bNodes: INode[] = [];

    const sortedProducts = products.sort((a, b) =>
      a.position > b.position ? 1 : -1
    );

    for (let i = 0; i < sortedProducts.length; i++) {
      if (products[i].parentId === 1) {
        let node: INode = {
          key: products[i].uuid,
          label: products[i].description + " " + products[i].id,
          url: "",
          product: products[i],
          nodes: [],
        };

        fillInstructions(node.nodes, node.product ? node.product.id : -1);
        fillOptions(node.nodes, node.product ? node.product.id : -1);

        fillChild(node.nodes, node.product ? node.product.id : -1);
        bNodes.push(node);
      }
    }
    setTreeNode(bNodes);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    buildTreeData();
  }, [products]);

  useEffect(() => {
    getBranchSettings();
  }, [refreshValue]);

  const viewAddProduct = (): JSX.Element => {
    return (
      <Modal
        show={showAddProduct}
        onHide={() => setShowAddProduct(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <AddProduct />
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowAddProduct(false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="danger"
              size="lg"
              //onClick={() => removeInstruction()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function findProductById(uuid: string) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.uuid === uuid;
    });
  }

  function findOptionById(uuid: string) {
    return context.BranchMenu.getMenu().options.find((element) => {
      return element.uuid === uuid;
    });
  }

  function addInstructions() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }

    navigate("/AddInstruction/" + product.id + "/defaultPId/TreeView");
  }

  function editInstructions() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];

    const instruction = context.BranchMenu.getMenu().instructions.find(
      (element) => {
        return element.uuid === id;
      }
    );

    if (instruction === undefined) {
      return;
    }
    navigate("/AddInstruction/" + instruction.id + "/edit/TreeView");
  }

  function addProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }

    navigate("/AddProduct/" + product.id + "/defaultPId/TreeView");
  }

  function editProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }
    navigate("/AddProduct/" + product.id + "/edit/TreeView");
  }

  async function removeProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }

    const prd: IProduct = {
      id: product.id,
      uuid: "",
      parentId: 0,
      description: "",
      imageUrl: "",
      position: 0,
      isCategory: false,
      price: 0,
      qty: 0,
      textColour: "",
      buttonColour: "",
      zone: 0,
      barcode: "",
    };

    const response = await deleteProduct(
      prd,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      sleep(3000);
      getBranchSettings();
    }
  }

  async function removeInstruction() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const instruction = findInstructionById(id);

    if (instruction === undefined) {
      return;
    }

    // const prd: IProduct = {
    //   id: product.id,
    //   uuid: "",
    //   parentId: 0,
    //   description: "",
    //   imageUrl: "",
    //   position: 0,
    //   isCategory: false,
    //   price: 0,
    //   qty: 0,
    //   textColour: "",
    //   buttonColour: "",
    //   zone: 0,
    //   barcode: "",
    // };

    const response = await deleteInstruction(
      instruction.id,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      sleep(3000);
      getBranchSettings();
    }
  }

  async function removeOption() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const option = findOptionById(id);

    if (option === undefined) {
      return;
    }

    const op: IOption = {
      id: option.id,
      uuid: "",
      description: "",
      position: 0,
      price: 0,
      qty: 0,
      productId: 0,
      image: "",
    };

    const response = await deleteOption(
      op,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      sleep(3000);
      getBranchSettings();
    }
  }

  function copyOverInstructions() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }
    navigate("/CopyInstructions/" + product.id + "/TreeView");
  }

  function copyInstruction() {
    //TODO: copy instruction to another product
    //target product and instruction

    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const instruction = findInstructionById(id);

    if (instruction === undefined) {
      return;
    }
    navigate("/CopyInstruction/" + instruction.id + "/TreeView");
  }

  function selectInstructions() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }
    navigate("/SelectInstructions/" + product.id + "/TreeView");
  }

  function findInstructionById(uuid: string) {
    return context.BranchMenu.getMenu().instructions.find((element) => {
      return element.uuid === uuid;
    });
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  function test(e: any, result: any) {
    const { name, value } = result;
    if (value === 1) {
      addProduct();
    }
    if (value === 2) {
      editProduct();
    }
    if (value === 3) {
      removeProduct();
    }
    if (value === 4) {
      copyOverInstructions();
    }
    if (value === 5) {
      copyInstruction();
    }
  }

  const menu = (): JSX.Element => (
    <div>
      {/* <Menu compact>
        <Dropdown
          text="Dropdown"
          options={options}
          simple
          item
          onChange={test}
        />
      </Menu> */}

      <Navbar className="bg-body-tertiary">
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Product" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => addProduct()}>
                  Add
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => editProduct()}>
                  Edit
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => removeProduct()}>
                  Delete
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => copyOverInstructions()}>
                  Copy instructions over
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => selectInstructions()}>
                  Select instructions
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Copy</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Instructions" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => addInstructions()}>
                  Add
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => editInstructions()}>
                  Edit
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => removeInstruction()}>
                  Delete
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => copyInstruction()}>
                  Copy
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => selectInstructions()}>
                  Select instructions
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Options" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Add</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Edit</NavDropdown.Item>
                <NavDropdown.Item onClick={() => removeOption()}>
                  Delete
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Copy</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </div>
  );

  const productsTree = (): JSX.Element => (
    <>
      <div className="card border-0">
        <div className="card-header">
          <h5 className="card-title">Products tree view</h5>
          {menu()}
        </div>
        <div className="card-body">
          <div
            style={{
              overflowY: "auto",
              padding: 0,
              height: "50rem",
              backgroundColor: "white",
            }}
          >
            <div>
              <TreeMenu
                data={treeNodes}
                resetOpenNodesOnDataUpdate
                initialOpenNodes={undefined}
                onClickItem={({ key, id, label, ...props }) => {
                  setSelectedId(key);
                }}
              >
                {({ items }) => (
                  <ul className="tree-item-group">
                    {items.map(({ key, onClickItem, ...props }) => (
                      <ItemComponent key={key} {...props} />
                    ))}
                  </ul>
                )}
              </TreeMenu>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <>{productsTree()}</>
      <>{viewAddProduct()}</>
    </>
  );
};
