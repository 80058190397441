import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { ISearchData } from "../../../types/ISearchData";
import { IExtra } from "../../../types/IExtra";
import { getSettings, storeExtra } from "../../../services/Service";
import { useNavigate, useParams } from "react-router-dom";
import { sleep } from "../../helpers/Helpers";

export const AddExtra: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [extraData, setExtraData] = useState<IExtra[]>([]);
  const [saving, setSaving] = useState(false);

  const [description, setDescription] = useState("extra ");
  const [imageURL, setImageUrl] = useState("default.png");
  const [price, setPrice] = useState("0.00");

  const [isValidDescription, setIsInvalidDescription] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);

  const navigate = useNavigate();
  const { nav } = useParams();

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setExtraData(response.menu.extras);
    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    return valid;
  }

  async function save() {
    const valid = validateMe();

    if (valid === false) {
      return;
    }

    const newExtra: IExtra = {
      id: 0,
      uuid: "",
      optionId: 0,
      description: description,
      image: imageURL,
      position: 0,
      price: Number(price),
      qty: 0,
    };

    setSaving(true);

    const saved = storeExtra(newExtra, context.AuthenticatedUser.getToken());

    if (!saved) {
      context.ApplicationError.addError("storing extra error");
      return;
    }

    await sleep(2000);

    setSaving(false);

    if (nav !== undefined) {
      navigate("/TreeView");
      return;
    }

    navigate("/Extras");
  }

  const add = (): JSX.Element => (
    <>
      <div className="container">
        <h1>Add extra</h1>
        <hr />
        <Form>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                isInvalid={isValidDescription}
                required={true}
                value={description}
                type="text"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Image URL
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={imageURL}
                placeholder="Image URL"
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Price
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Price"
                isInvalid={isValidPrice}
                defaultValue={(0).toFixed(2)}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button variant="primary" onClick={save} disabled={saving}>
                {saving ? "Saving..." : "Create"}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );

  return <>{add()}</>;
};
