import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { IProduct } from "../../../types/IProduct";
import { useNavigate } from "react-router-dom";
import { deleteProduct, getSettings } from "../../../services/Service";

export interface IAllProductsProps {
  editId(id: number): void;
}

export const AllProducts: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const navigate = useNavigate();

  //const [searchData, setSearchData] = useState<ISearchData[]>([]);
  //const [searchValue, setSearchValue] = useState("Home");
  const [showConfirm, setShowConfirm] = useState(false);
  const [removeProductId, SetRemoveProductId] = useState(-1);

  const [products, setProducts] = useState<IProduct[]>([]);

  const [refresh, setRefresh] = useState("");

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);

    const p = context.BranchMenu.getMenu().products.sort((a, b) =>
      a.position > b.position ? 1 : -1
    );

    setProducts(p);

    setRefresh(uuidv4());
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function edit(p: IProduct) {
    navigate("/AddProduct/" + p.id);
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  function removeProduct(p: IProduct) {
    setShowConfirm(true);
    SetRemoveProductId(p.id);
  }

  async function removeInstruction() {
    const prd: IProduct = {
      id: removeProductId,
      uuid: "",
      parentId: 0,
      description: "",
      imageUrl: "",
      position: 0,
      isCategory: false,
      price: 0,
      qty: 0,
      textColour: "",
      buttonColour: "",
      zone: 0,
      barcode: "",
    };

    const response = await deleteProduct(
      prd,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      getBranchSettings();
      setShowConfirm(false);
    }
  }

  const confirmView = (): JSX.Element => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Are you sure?</h5>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="danger"
              size="lg"
              onClick={() => removeInstruction()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const productRow = (p: IProduct) => (
    <>
      <TableRow>
        <TableCell positive>{p.id}</TableCell>
        <TableCell positive>{p.parentId}</TableCell>
        <TableCell positive>{p.description}</TableCell>
        <TableCell positive>{p.position}</TableCell>
        <TableCell positive>{p.price.toFixed(2)}</TableCell>
        <TableCell positive>{p.imageUrl}</TableCell>
        <TableCell positive>{p.qty}</TableCell>
        <TableCell positive>{p.isCategory ? "Yes" : "no"}</TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => edit(p)}>
            Edit
          </Button>
        </TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => removeProduct(p)}>
            X
          </Button>
        </TableCell>
      </TableRow>
    </>
  );

  const productsView = (): JSX.Element => (
    <>
      <div className="card border-0">
        <div className="card-header">
          <h5 className="card-title">Products</h5>
          <h6 className="card-subtitle text-muted"></h6>
        </div>
        <div className="card-body">
          <div style={{ overflowY: "auto", padding: 0, height: "80vh" }}>
            <Table celled>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Id</TableHeaderCell>
                  <TableHeaderCell>ParentId</TableHeaderCell>
                  <TableHeaderCell>Description</TableHeaderCell>
                  <TableHeaderCell>Position</TableHeaderCell>
                  <TableHeaderCell>Price</TableHeaderCell>
                  <TableHeaderCell>Image URL</TableHeaderCell>
                  <TableHeaderCell>Qty</TableHeaderCell>
                  <TableHeaderCell>Is Category</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>

              <TableBody>{products.map((data) => productRow(data))}</TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <>{confirmView()}</>
      <>{productsView()}</>
    </>
  );
};
