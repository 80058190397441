import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { getSettings } from "../../../services/Service";
import { IVariation } from "../../../types/IVariation";

export const Variations: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [allVariations, setAllVariations] = useState<IVariation[]>([]);

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setAllVariations(response.menu.variations);
    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  const row = (v: IVariation) => (
    <>
      <TableRow>
        <TableCell positive>{v.id}</TableCell>
        <TableCell positive>{v.optionId}</TableCell>
        <TableCell positive>{v.description}</TableCell>
        <TableCell positive>{v.image}</TableCell>
        <TableCell positive>{v.position}</TableCell>
        <TableCell positive>{v.price.toFixed(2)}</TableCell>
        <TableCell positive>{v.qty}</TableCell>
      </TableRow>
    </>
  );

  const variations = (): JSX.Element => (
    <>
      <h1>Variations</h1>
      <hr />
      <div>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Id</TableHeaderCell>
              <TableHeaderCell>Option Id</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Image Url</TableHeaderCell>
              <TableHeaderCell>Position</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Qty</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>{allVariations.map((data) => row(data))}</TableBody>
        </Table>
      </div>
    </>
  );

  return <>{variations()}</>;
};
