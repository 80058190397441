import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import {
  getSettings,
  storeProduct,
  updateProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GenerateGUID } from "../../../helpers/Helpers";

export interface ITableData {
  uuid: string;
  id: number;
  position: number;
  description: string;
  price: number;
}

const defaultProduct: IProduct = {
  id: 0,
  uuid: "",
  parentId: 0,
  description: "",
  imageUrl: "",
  position: 0,
  isCategory: false,
  price: 0,
  qty: 0,
  textColour: "",
  buttonColour: "",
  zone: 0,
  barcode: "",
};

export const AddProduct: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [product, setProduct] = useState(defaultProduct);

  const { id } = useParams();
  const { type } = useParams();
  const { nav } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState("");

  const [searchData, setSearchData] = useState<ISearchData[]>([]);
  const [searchValue, setSearchValue] = useState("Home");
  const [saving, setSaving] = useState(false);

  const [instructionsData, setInstructionsData] = useState<ISearchData[]>([]);

  const [zones, setZones] = React.useState<DropdownItemProps[]>([]);
  const [selectedZone, setSelectedZone] = React.useState(0);

  //selected values
  const [productId, setProductId] = useState(1);
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("default.jpg");
  const [position, setPosition] = useState("0");
  const [price, setPrice] = useState("0.00");
  const [qty, setQty] = useState("1");
  const [isCategory, setIsCategory] = useState(false);
  const [isCustomColor, setIsCustomColor] = useState(false);
  const [textColor, setTextColor] = useState("#000000");
  const [bgColor, setBgColor] = useState("#ffffff");
  const [barcode, setBarcode] = useState("0");

  //validation
  const [isValidDescription, setIsInvalidDescription] = useState(false);
  const [isValidPosition, setIsInvalidPosition] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);
  const [isValidQty, setIsInvalidQty] = useState(false);

  const navigate = useNavigate();

  async function getBranchSettings() {
    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    // products
    let searchData: ISearchData[] = [];
    response.menu.products.forEach((p) => {
      const d: ISearchData = {
        productId: p.id,
        value: p.id.toString(),
        key: p.id.toString(),
        text: p.description + " (" + p.id + ")",
        barcode: "",
      };
      searchData.push(d);
    });
    setSearchData(searchData);

    //instructions
    let iData: ISearchData[] = [];
    response.menu.instructions.forEach((d) => {
      const d1: ISearchData = {
        productId: d.id,
        value: d.id.toString(),
        key: d.id.toString(),
        text: d.description,
        barcode: "",
      };
      iData.push(d1);
    });
    setInstructionsData(iData);

    context.BranchMenu.setMenu(response.menu);
  }

  function populateZones() {
    let z: DropdownItemProps[] = [];

    for (let i = 0; i < 20; i++) {
      const d: DropdownItemProps = {
        text: "Zone : " + i,
        value: i,
      };
      z.push(d);
    }

    setZones(z);
  }

  useEffect(() => {
    getBranchSettings();
    populateZones();
  }, []);

  useEffect(() => {
    setEditMode(false);
    //type and id
    if (type === undefined || id === undefined) {
      return;
    }

    if (type === "edit") {
      edit(Number(id));
      return;
    }

    if (type === "defaultPId") {
      defaultSelectedProduct(Number(id));
    }
  }, []);

  function defaultSelectedProduct(id: number) {
    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    setSearchValue(product.description);
    setProductId(product.id);
  }

  function edit(id: number) {
    setEditMode(true);
    setEditId(id.toString());

    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    const parentProduct = findProductByProductId(product.parentId);

    if (parentProduct === undefined) {
      return;
    }

    setSearchValue(parentProduct.description);
    setProductId(parentProduct.id);
    setDescription(product.description);
    setImageUrl(product.imageUrl);
    setPosition(product.position.toString());
    setPrice(product.price.toFixed(2));
    setQty(product.qty.toString());
    setIsCategory(product.isCategory);
    setBgColor(product.buttonColour);
    setTextColor(product.textColour);
    setBarcode(product.barcode);
    setSelectedZone(product.zone);
  }

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSearchValue(found.description + " (" + found.id + ")");
    setProductId(found.id);
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    //check position
    const pos = parseFloat(position);
    if (position.length === 0 || isNaN(pos)) {
      setIsInvalidPosition(true);
      valid = false;
    } else {
      setIsInvalidPosition(false);
    }

    //price
    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    //qty
    const q = parseFloat(qty);
    if (qty.length === 0 || isNaN(q)) {
      setIsInvalidQty(true);
      valid = false;
    } else {
      setIsInvalidQty(false);
    }

    return valid;
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function saveProduct() {
    const valid = validateMe();

    if (valid === false) {
      return;
    }

    product.id = editMode ? Number(editId) : -1; //if it has an id it will be in edit mode
    product.parentId = productId;
    product.description = description;
    product.imageUrl = imageURL;
    product.position = Number(position);
    product.isCategory = isCategory;
    product.price = parseFloat(price);
    product.textColour = textColor;
    product.buttonColour = bgColor;
    product.qty = Number(qty);
    product.zone = selectedZone;
    product.barcode = barcode;

    setSaving(true);

    if (!editMode) {
      const newProduct = storeProduct(
        product,
        context.AuthenticatedUser.getToken()
      );

      if (!newProduct) {
        context.ApplicationError.setHasError(true);
        return;
      }

      context.Data.setRefreshUuId(GenerateGUID());
    }

    if (editMode) {
      const uProduct = updateProduct(
        product,
        context.AuthenticatedUser.getToken()
      );

      if (!uProduct) {
        context.ApplicationError.setHasError(true);
        return;
      }

      context.Data.setRefreshUuId(GenerateGUID());
    }

    await sleep(2000);

    setSaving(false);

    if (nav !== undefined) {
      navigate("/TreeView");
      return;
    }

    navigate("/Products");
  }

  function toggleIsCategory() {
    if (isCategory === true) {
      setIsCategory(false);
    } else {
      setIsCategory(true);
    }
  }

  function zoneSelected(event: any, { value }: any) {
    setSelectedZone(value);
  }

  const searchProduct = () => (
    <>
      <Dropdown
        button
        //className="icon"
        fluid
        //labeled
        //icon="world"
        options={searchData}
        //search
        //clearable={true}
        value={searchValue}
        defaultSearchQuery={searchValue}
        searchQuery={searchValue}
        text={searchValue}
        //onSearchChange={handleOnSearchChange}
        onChange={onProductSelected}
        basic={true}
        onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  const add = (): JSX.Element => (
    <>
      <div className="container">
        <h1>{editMode ? <>Update Product</> : <>Add Product</>}</h1>
        <hr />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Parent
            </Form.Label>
            <Col sm={10}>{searchProduct()}</Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                isInvalid={isValidDescription}
                required={true}
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Image URL
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Image URL"
                value={imageURL}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Position
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Position"
                isInvalid={isValidPosition}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Price
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Price"
                isInvalid={isValidPrice}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Qty
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Qty"
                isInvalid={isValidQty}
                value={qty}
                onChange={(e) => setQty(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Text Colour
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="color"
                value={textColor}
                onChange={(c) => setTextColor(c.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Btn Colour
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="color"
                value={bgColor}
                onChange={(c) => setBgColor(c.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Zone/Location
            </Form.Label>
            <Col sm={10}>
              <Dropdown
                placeholder="Zones"
                search
                selection
                value={selectedZone}
                options={zones}
                onChange={zoneSelected}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Identifier
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="e.g. barcode"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
            <Col sm={{ span: 10, offset: 2 }}>
              <Form.Check
                label="Is category"
                checked={isCategory}
                onClick={(e) => toggleIsCategory()}
              />
            </Col>
          </Form.Group>

          <br />
          <br />
        </Form>
      </div>

      <Button disabled={saving} variant="success" onClick={() => saveProduct()}>
        {saving ? (
          "Saving..."
        ) : editMode ? (
          <>Update Product</>
        ) : (
          <>Add Product</>
        )}
      </Button>

      <br />
      <br />
    </>
  );

  return (
    <>
      <>{add()}</>
    </>
  );
};
